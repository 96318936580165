//All Routes
export const Home = "/";
export const connect2Services = "/2880302f0577fbf18f61192b049ed524";
export const connect2ServicesIntro = "/pushstart-partners";

export const Events = "/events";
export const Details = "/events/details";
export const Events2 = '/events2';

export const WhatsappForm = "/join-network-community";

export const WhatsappFormThanks = "/join-network-community/Thank-you";



export const Feed = "/feed";

export const Post = "/feed/:id";