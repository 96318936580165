import React, { Component } from 'react';
import './styles/loader.css';

import Logo from './img/logo.png';
import LogoAnimated from './img/ps_logo_animated.gif';

class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <div className="loader-img">
          <img src={Logo} alt="Pushstart" />
        </div>
      </div>
    );
  }
}

export default Loader;
