import React, { Component, Suspense, lazy } from "react";
import { Switch, BrowserRouter, Route, withRouter } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import * as routes from "./services/routes.js";
import { render } from "react-dom";
import Loader from "./components/Loader/loader";

class App extends Component {
  render() {
    const Home = withRouter(lazy(() => import("./view/home/home")));
    const c2s = withRouter(lazy(() => import("./view/connect2service/connect2service")));
    const c2si = withRouter(lazy(() => import("./view/connect2service/connect2serviceIntro")));
    const whatsapp = withRouter(lazy(() => import("./view/whatsapp/whatsappform")));
    const whatsappThanks = withRouter(lazy(() => import("./view/whatsapp/thankyou")));
    const feed = withRouter(lazy(() => import("./view/blogs/blogs_main")));
    const post = withRouter(lazy(() => import("./view/blogs/body/blog_full_lenght")));

    return (
      <div className="App" class="main-bg">
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path={routes.Home} component={Home} />
              <Route exact path={routes.connect2Services} component={c2s} />
              <Route exact path={routes.connect2ServicesIntro} component={c2si} />
              <Route exact path={routes.WhatsappForm} component={whatsapp} />
              <Route exact path={routes.WhatsappFormThanks} component={whatsappThanks} />
              <Route exact path={routes.Feed} component={feed} />
              <Route exact path={routes.Post} component={post} />

            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
